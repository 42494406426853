import React from 'react';
import {Elements} from "@stripe/react-stripe-js";
import NoCashCheckoutForm from "./checkout/card/NoCashCheckoutForm";
import {loadStripe} from "@stripe/stripe-js";
import styles from './styles.module.css';

const stripePromise = loadStripe('pk_live_51JDSkcH8cMQfrIVyeB5uqP2rHebphnA9kAqCAe0GMNF0mC0uUXZEmTL36gUC10pIYuctKgLUdm3osDKZgWQXJktj008m3VSvHf',{stripeAccount: 'acct_1P6IA7LCXj9FQK5l'});

interface IProps {
    amount: number,
    name: string
}

const Payment: React.FC<IProps> = ({amount, name}) =>
    <div>
        <div className="flex text-white text-lg place-content-center space-x-4 p-4">
            <span>Payment amount </span>
            <span> {amount} €</span>
        </div>
        <div className={`lg:h-full lg:w-3/4
        md:h-3/4 md:w-full
        sm:h-3/4 sm:w-full
        flex-col md:flex-row ${styles.paymentContainer}`}>
            <Elements stripe={stripePromise}>
                <NoCashCheckoutForm amount={amount} name={name} />
            </Elements>
        </div>
    </div>
export default Payment
