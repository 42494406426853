import React from 'react';
import styles from './styles.module.css';



function SuccessPopup() {
    return (
        <div className={`flex flex-col w-full mx-auto form-bg text-gray-300 text-center ${styles.popupContainer}`}>
            <p>Success!</p>
            <p> Thanks for your tips</p>
            <div className={styles.popupImage}>
            </div>
        </div>
    );
}

export default SuccessPopup;
