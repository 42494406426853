import React from "react";
import TipButton from "./tip-button/TipButton";
import TipInput from "./tip-input/TipInput";
import Radiobuttons from "../review/radiobuttons/Radiobuttons";
import Input from "../review/input/Input";
import CardPayButton from "../../payment/CardPayButton";
import Rate from "../review/rate/Rate";
import RadioButton from "../review/radiobuttons/RadioButton";
import {ReactComponent as ClearButton} from "../../../img/svg/clear_tip.svg";

const amountList = [2, 4, 8, 12, 16, 20]

interface IProps {
    amount: number
    handleAmount: (e: number) => void
    uuid: string
    checked: boolean
    handleChecked: () => void;
}

const Tip: React.FC<IProps> = ({amount, handleAmount, uuid}) => {

    const [starValue, setStarValue] = React.useState<number | null>(0);

    const handleStarValue = (value: number | null) => {
        setStarValue(value);
    }

    return (
        <div className="
        w-full
        h-auto
        sm:w-10/12
        lg: w-full
        mx-auto
        border-solid shadow-2xl
        pt-6
        customer-rounded
        border-t-0
        bg-gray-700
        -mt-10
        lg:mt-0

        ">
            <div className="mb-6 max-w-max md:w-3/4 flex flex-col justify-center mx-auto">
                <TipInput handleAmount={handleAmount} amount={amount} id={'id'}/>
                <div className="flex mt-4 xs:w-30  flex-wrap justify-center gap-2">
                    {amountList.map(e => <TipButton
                        bgColor={e === amount ? 'gradient' : 'tipButton'}
                        handleAmount={handleAmount} buttonAmount={e} amount={amount} key={e}
                        id={e === amount ? 'enabled' : 'disabled'}
                    />)}

                </div>
            </div>
            <Rate starValue={starValue} handleStarValue={handleStarValue}/>
            {
                starValue != null && starValue < 5 && starValue !== 0
                    ?
                    <div>
                        <div className="flex-col w-60 justify-center mx-auto text-white">
                            <h3 className="text-center mb-6">What can we do better?</h3>
                            <Radiobuttons/>
                        </div>
                        <Input/>
                    </div>
                    :
                    <div/>
            }
            <div className="w-10/12 h-auto flex mx-auto text-white justify-center text-center">
                <RadioButton id={'7'}
                             content={`I want to cover a process fee so that the recepient gets the full amount`}/>
            </div>
            <div className="w-72 flex-col sm:flex-row mx-auto lg:w-4/5 lg:justify-center lg:flex mt-4">
                <CardPayButton uuid={uuid} amount={amount}/>
            </div>
            <p className="w-60 text-center text-white mx-auto mt-4 pb-10 text-xs whitespace-pre-wrap">
                By tipping you agree to our
                <br/>
                <a href="https://nocash.me/" className="underline">Terms of use</a> and
                <a href="https://nocash.me/" className="underline"> Privacy Policy</a>
            </p>
        </div>
    )
}

export default Tip

