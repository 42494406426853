import React from "react";

interface IProps {
    amount: number,
    buttonAmount: number,
    handleAmount: (amount: number) => void,
    bgColor: string,
    id: string
}

const handleMask = (amount: number) => {
    const digit = /[0-9]/;
    let mask = [digit];

    let numbers = amount.toString().length;

    while (mask.length < numbers)
        mask.unshift(digit);

    return (mask + ' €')
};

const TipButton: React.FC<IProps> = ({buttonAmount, amount, handleAmount, bgColor, id}) => {
    return (<span id={id} onClick={() => {
        handleAmount(buttonAmount);
    }
    }
                  className={`w-24 h-12 sm:w-32 sm:h-16 bg-${bgColor} 
                        py-3 px-6 sm:text-xl rounded-3xl mr-2 sm:mr-4 last:mr-0 
                       flex items-center justify-center text-white`}
    >
        {'€' + buttonAmount}

    </span>)
}



export default TipButton
