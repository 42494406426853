import React, {useRef, useState} from "react";
import TipNumberFormat from "./tip-number-format/TipNumberFormat";
import {ReactComponent as ClearButton} from '../../../../img/svg/clear_tip.svg';
import {log} from "util";
interface IProps {
    amount: number
    handleAmount: (amount: number) => void
    id: string
}

// @ts-ignore
const TipInput: React.FC<IProps> = ({amount, handleAmount, id }) => {

    function handleReset() {
        handleAmount(0);
        const input = document.querySelectorAll("input").item(0);
        const tipBtn = document.querySelectorAll('button').item(1);
        const activeAmountBtn = document.getElementById('enabled');
        input.value = '';
        tipBtn.disabled = true;
        if (activeAmountBtn) {
            activeAmountBtn.classList.remove('bg-gradient');
            activeAmountBtn.classList.add('bg-tipButton');
        }
    }

    return (
        <div className="flex justify-center mt-10">
            <TipNumberFormat id={'id'} amount={amount} handleAmount={handleAmount}/>
            <div className="mt-1 ml-2">
                <button className='bg-none' onClick={handleReset}>
                    <ClearButton/>
                </button>
            </div>

        </div>
    )
};

export default TipInput
