import React from 'react';
import logo from '../../img/svg/logo.svg';
import styles from './styles.module.css';

interface IProps {
    toggleLanguage: any;
    language: string;
}

const customStyles = {
    option: () => ({
        borderBottom: '0px',
        color: 'white',
    }),
    control: () => ({
        width: 100,
        display: 'flex',
        color: 'white',
        paddingRight: 10,
        background: 'transparent',
    }),
    menu: () => ({
        width: 100,
        display: 'flex',
        color: 'gray',
        paddingLeft: 10,
        background: 'transparent',
        zIndex: 100,
        marginRight: 10,

    }),
    singleValue: () => {
        const opacity = 0.5;
        const transition = 'opacity 300ms';
        const background = 'transparent';
        const color = 'white';

        return {opacity, transition, background};
    },
    buttonStyle: () => {

        const background = 'none';
        const color = 'inherit';
        const border = 'none';
        const padding = 0;
        const font = 'inherit';
        const cursor = 'pointer';
        const outline = 'inherit';

        return {background, color, border, padding, font, cursor, outline};

    }
}


const Header: React.FC<IProps> = ({language, toggleLanguage}) =>
    <div className={styles.header}>
        <a href="/">
            <img src={logo} alt="NoCash"/>
        </a>
    </div>
export default Header

