import React from "react";
import './radiobuttons.css';
import RadioButton from "./RadioButton";

const radioArray = [
    {
        id: 'radio1',
        content: 'Cleanliness',
    },
    {
        id: 'radio2',
        content: 'Comfort',
    },
    {
        id: 'radio3',
        content: 'Dining',
    },
    {
        id: 'radio4',
        content: 'Location',
    },
    {
        id: 'radio5',
        content: 'Wi-Fi',
    },
    {
        id: 'radio6',
        content: 'Service',
    },

]

const RadioButtons: React.FC = () =>
    <div className="grid grid-cols-2 sm:grid-cols-3 justify-between">
        {
            radioArray.map(e =>
                <RadioButton id={e.id} content={e.content}/>
            )
        }
    </div>


export default RadioButtons;
